import React from "react"
import VideoLayout from "./videoLayout"

function Events() {
  return (
    <VideoLayout title="Events">
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596092427/videos/misc/vid-1_l2yikv.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596092448/videos/misc/vid-2_ev6nju.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596092463/videos/misc/vid-3_ihkv7r.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596092449/videos/misc/vid-4_dpzikm.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596092471/videos/misc/vid-5_piwxit.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596092450/videos/misc/vid-6_abunt6.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596092469/videos/misc/vid-7_hzvliu.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596092469/videos/misc/vid-8_m0ienx.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596092468/videos/misc/vid-9_xlbo0p.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1596092476/videos/misc/vid-10_zbvrkx.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871351/videos/misc/ec3edca51e66cb3d21421b87a97517_zxmtuh.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871546/videos/misc/44781_flbzmw.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871568/videos/misc/00ef9f3508fe6c898f2ff62b114d4e_q92rgp.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871608/videos/misc/6c94f49be795590414c1f9615389f7_o74fyq.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871617/videos/misc/48458da66f43b999141bcbe4143319_wldrck.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871691/videos/misc/18962357fb5b7a6e36c193ce5ba7c4_s3qwhz.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871726/videos/misc/b5e677860fa4900f06ae0a76a7a8bd_cg69pp.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871804/videos/misc/e26ae98dc98fa2ee82362107cc75f4_ikn4zf.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871916/videos/misc/7a5e55f29360e43bd5f9bfa9889d80_cxpnkl.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871935/videos/misc/f7561157f3547247940f5b1a52bd23_c86ipf.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652871989/videos/misc/IMG_3527_dk6xup.mov"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652872006/videos/misc/ec3edca51e66cb3d21421b87a97517_1_z8sn7c.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <video width="390" height="220" controls muted>
        <source
          src="https://res.cloudinary.com/diw3segy3/video/upload/v1652872016/videos/misc/72cd9d9c13a1d98109382b64a1f4a2_qhjhjs.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </VideoLayout>
  )
}

export default Events
